import React, { useState } from 'react';
import axios from 'axios';
import { useUser } from '../contexts/UserContext';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Menu, MenuItem } from '@mui/material'; // Assuming you're using MUI for UI components

function Login() {
    const { user, setUser } = useUser();
    const [inputUsername, setInputUsername] = useState('');
    const [inputPassword, setInputPassword] = useState('');
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false); // State to manage dialog visibility
    const [anchorEl, setAnchorEl] = useState(null); // State for menu position

    const handleLogin = async (e) => {
        e.preventDefault();
        const authUrl = process.env.REACT_APP_FORECAST_AUTH_URL;
        try {
            const response = await axios.post(`${authUrl}/login`, { 'inputUsername': inputUsername, 'inputPassword': inputPassword });
            sessionStorage.setItem('user', JSON.stringify(response.data));
            setUser(response.data);
            handleCloseDialog();
        } catch (err) {
            setError(err.response?.data?.message || 'An error occurred during login');
        }
    };

    const handleLogout = () => {
        sessionStorage.removeItem('user');
        setUser(null);
        handleCloseMenu();
    };

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
        handleCloseMenu();
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickMenu}>
                {user ? (
                    <>Logout &#x2190;</>
                    ) : (
                    <>Login &#x2192;</>
                )}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                {user ? (
                    <>
                        <MenuItem disabled>({user.role}) {user.name}</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </>
                ) : (
                    <MenuItem onClick={handleOpenDialog}>Login</MenuItem>
                )}
            </Menu>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Login</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        id="username"
                        label="Username"
                        type="text"
                        fullWidth
                        value={inputUsername}
                        onChange={(e) => setInputUsername(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        value={inputPassword}
                        onChange={(e) => setInputPassword(e.target.value)}
                    />
                    {error && <p>{error}</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleLogin}>Login</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Login;
