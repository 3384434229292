// src/App.js
import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
import { useUser } from './contexts/UserContext'; // Adjust the path as necessary
import ForecastChart from './components/ForecastChart';
import Login from './components/Login';
import TowerSelector from './components/TowerSelector';
import ForecastChartWithConfigurations from './components/ForecastChartWithConfigurations';

function App() {
  const { user } = useUser();

  const addUser = () => {
	const authUrl = process.env.REACT_APP_FORECAST_AUTH_URL;
    axios.post(`${authUrl}/addUser`, {
      name: 'simon',
      role: 'admin',
      password: 'merlung',
    })
    .then(response => console.log(response.data))
    .catch(error => console.error('Error:', error));
  };

  return (
	<div className="App">
	  <div className="top-bar">
		<TowerSelector />
		{user && user.role === 'admin' && (
          <button onClick={addUser}>Create User</button>
        )}
		<div>
			<Login />
		</div>
	  </div>
	  <div className="content">
		<h1>Baltic Eagle: Wave-induced WTG vibrations</h1>
			<section className="chart">
			<h2>Response RMS</h2>
			<ForecastChartWithConfigurations tableName="responseRMS" yAxisLabel="RMS [mm]" yAxisMin="0" yAxisMax="200" yAxisDecimals="0" />
			</section>
			<section className="chart">
			<h2>Significant Wave Height</h2>
			<ForecastChart tableName="significantWaveHeight" yAxisLabel="[m]" yAxisDecimals="1" />
			</section>
			<section className="chart">
			<h2>Dominant Wave Period</h2>
			<ForecastChart tableName="dominantWavePeriod" yAxisLabel="[s]" yAxisDecimals="1" />
			</section>
			<section className="chart">
			<h2>Sea Mean Deviation</h2>
			<ForecastChart tableName="seaMeanDeviation" yAxisLabel="[m]" yAxisDecimals="1" />
			</section>
	  </div>
	  <div>
	  	<a href="https://statuspage.freshping.io/25721-DMIOpenDatas">DMI Service Status</a>
	  </div>
	</div>
  );
}

export default App;
