import React, { createContext, useContext, useState } from 'react';

// Create the TimeZoneContext
const TimeZoneContext = createContext();

// Helper hook to use the timezone context in components
export const useTimeZone = () => {
  return useContext(TimeZoneContext);
};

// Provider component to wrap your app with timezone configuration
export const TimeZoneProvider = ({ children, defaultTimeZone }) => {
  const [timezone, setTimezone] = useState(
    defaultTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const value = {
    timezone,
    setTimezone,
  };

  return (
    <TimeZoneContext.Provider value={value}>
      {children}
    </TimeZoneContext.Provider>
  );
};
