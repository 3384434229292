import React, { createContext, useContext, useState, useEffect } from 'react';

const TowerContext = createContext();

export const useTower = () => useContext(TowerContext);

export const TowerProvider = ({ children }) => {
  const [selectedTower, setSelectedTower] = useState(() => {
    // Attempt to load the selected tower from localStorage
    const savedTower = localStorage.getItem('selectedTower');
    return savedTower ? JSON.parse(savedTower) : null;
  });

  useEffect(() => {
    // Persist the selected tower to localStorage when it changes
    localStorage.setItem('selectedTower', JSON.stringify(selectedTower));
  }, [selectedTower]);

  return (
    <TowerContext.Provider value={{ selectedTower, setSelectedTower }}>
      {children}
    </TowerContext.Provider>
  );
};
