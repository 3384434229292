import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './contexts/UserContext';
import { TowerProvider } from './contexts/TowerContext';
import { TimeZoneProvider } from './contexts/TimeZoneContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TimeZoneProvider defaultTimeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}>
    {/* <TimeZoneProvider defaultTimeZone="Australia/Sydney"> */}
      <UserProvider>
        <TowerProvider>
          <App />
        </TowerProvider>
      </UserProvider>
    </TimeZoneProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
