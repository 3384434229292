// src/components/ForecastChart.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  registerables
} from 'chart.js';
import { addHours } from 'date-fns';
import { format, toZonedTime } from 'date-fns-tz';

import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useTower } from '../contexts/TowerContext';
import { useTimeZone } from '../contexts/TimeZoneContext';
import getChartOptions from './getChartOptions';

Chart.register(
  ...registerables,
  annotationPlugin
);

const ForecastChart = ({ tableName, yAxisLabel, yAxisMin, yAxisMax, yAxisDecimals }) => {
  const { selectedTower, setSelectedTower } = useTower();
  const { timezone, setTimezone } = useTimeZone();

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{
      label: '',
      data: [],
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1,
    }],
  });

  const fetchForecastData = async () => {
    if (!selectedTower) {
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_FORECAST_API_URL;
      const response = await axios.get(`${apiUrl}/forecast/${tableName}/${selectedTower['id']}`);
      const data = response.data[0];
      prepareChartData(data);
    } catch (error) {
      console.error("Error fetching forecast data:", error);
    }
  };

  const prepareChartData = (data) => {
    const baseTimestamp = new Date(data.timestamp); // Convert timestamp to JavaScript Date object
    const chartLabels = [];
    const chartValues = [];
  
    Object.keys(data).forEach(key => {
      if (key.startsWith('hour')) {
        // Extract the hour number from the key
        const hourOffset = parseInt(key.match(/\d+/)[0], 10);
        // Calculate new datetime by adding hourOffset to the baseTimestamp
        const datetime = addHours(baseTimestamp, hourOffset);
        const localDatetime = toZonedTime(datetime, timezone);

        const formattedDateTime = format(localDatetime, 'yyyy-MM-dd HH:mm');

        chartLabels.push(formattedDateTime);
        chartValues.push(data[key]);
      }
    });
  
    setChartData({
      labels: chartLabels,
      datasets: [
        {
          label: `Forecast for ${tableName}`,
          data: chartValues,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
        },
      ],
    });
  };

  useEffect(() => {
    fetchForecastData();
  }, [tableName, selectedTower]);


  return chartData && chartData.datasets && chartData.datasets[0].data.length > 0 ? (
    <div style={{ height: '80vh', width: '90vw' }}>
      <Line data={chartData} options={getChartOptions(chartData, yAxisLabel, yAxisMin, yAxisMax, yAxisDecimals)} />
    </div>
  ) : (
    <p>Loading...</p>
  );

};

export default ForecastChart;
